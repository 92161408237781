import React from 'react'

export default function Footer() {
  return (
    <footer>

      <div className="flex items-center justify-center flex-col">
        <div className="bg-gray-800 p-10 rounded-xl">
          <div className="flex flex-col justify-center items-center text-center">
            <div className="max-w-sm font-bold font-sans">
              Get in touch
            </div>
            <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
              <li> Email:
                <a href="mailto:chrisdalbertsen@gmail.com">
                  <button type="button"
                    className="text-purple-600 bg-gray-750 hover:text-gray-100 hover:bg-purple-600 font-medium transition duration-150 ease-in-out rounded-lg text-sm px-2 py-2.5 text-center mr-2 mb-2 rounded-full">
                      chrisdalbertsen@gmail.com
                    </button>
                </a>
              </li>
              <li className="ml-4 flex justify-center items-center">
                Phone: +45 24 85 76 99
              </li>
              <li className="ml-4">
                <a href="https://github.com/ChrisAlbertsen" className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out mr-2 mb-2 px-2" aria-label="Linkedin">
                  Linkedin: 
                  <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.3 8H8.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V8.7c-.1-.4-.4-.7-.8-.7zM12.7 21.6h-2.3V14h2.4v7.6h-.1zM11.6 13c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8h-2.4V14h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>



    </footer>
  )
}



// <div className="py-12 md:py-12">
// <div className="max-w-6xl mx-auto px-2 sm:px-6">

//   {/* Bottom area */}
//   <div className="md:flex md:items-center md:justify-between">

//     {/* Social as */}
//     <ul className="flex mb-4 md:order-1 md:ml-4 md:mb-0">
//       <li>
//         <a href="mailto:chrisdalbertsen@gmail.com">
//         <button type="button"
//           className="text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 font-medium transition duration-150 ease-in-out rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Red to Yellow</button>
//         </a>

//       </li>
//       <li>
//         <a href="https://www.linkedin.com/in/chris-albertsen/" className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out" aria-label="Github">
//           <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
//             <path d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z" />
//           </svg>
//         </a>
//       </li>
//       <li className="ml-4">
//         <a href="https://github.com/ChrisAlbertsen" className="flex justify-center items-center text-purple-600 bg-gray-800 hover:text-gray-100 hover:bg-purple-600 rounded-full transition duration-150 ease-in-out" aria-label="Linkedin">
//           <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
//             <path d="M23.3 8H8.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V8.7c-.1-.4-.4-.7-.8-.7zM12.7 21.6h-2.3V14h2.4v7.6h-.1zM11.6 13c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8h-2.4V14h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z" />
//           </svg>
//         </a>
//       </li>
//     </ul>
//     <div></div>

//   </div>

// </div>
// </div>