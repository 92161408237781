import React, { useEffect } from 'react';
import './css/style.css'
import Header from './components/ui/header'
import Footer from './components/ui/footer'
import Hero from './components/hero'
import Features from './components/features';
import Testimonials from './components/testimonials';
import Zigzag from './components/zigzag';
import AOS from 'aos';
import 'aos/dist/aos.css'

function RootLayout() {

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 600,
      easing: 'ease-out-sine',
    })
  })

  return (
    <>
      <html lang="en">
        <main className='grow'>
          <body className={`font-inter antialiased bg-gray-900 text-gray-200 tracking-tight`}>
            <div className="flex flex-col min-h-screen overflow-hidden">
              <Header/>
              <Hero />
              <Features/>
              <Zigzag/>
              <Testimonials/>
              <Footer/>
            </div>
          </body>
        </main>
      </html>
    </>
  )
}

function App() {
  return (
    <RootLayout/>
  );
}

export default App;
